import React from "react";
import './Faq.scss';
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from '../../Images/Logo.png'
import closeMenu from '../../Images/closeMenu.png'
import telephone from '../../Images/telephone.png'
import book from '../../Images/book.png'
// images
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"


export default function Faq({ Blogs, FaqList }) {
    const navigate = useNavigate()
    const [faqlistFilter, setfaqlistFilter] = useState(FaqList);
    const [faqlistInputValue, setfaqlistInputValue] = useState("");
    function readingTime(S) {
        const text = S.Text;
        const wpm = 225;
        const words = text.trim().split(/\s+/).length;
        const time = Math.ceil(words / wpm);
        return time
    }
    const getSpecificNumberBlog = () => {
        const number = Math.random() * Blogs.length
        if (number <= (Blogs.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberBlog()
        }
    }
    const [getNumberBlog, setgetNumberBlog] = useState(getSpecificNumberBlog());
    const location = useLocation()
    useEffect(() => {
        // console.log(location.hash);
    }, []);

    return (


        <div className="Faq">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>FAQ | Moreno Valley Chimney Sweep - Your Chimney Service Questions Answered</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="FAQ | Moreno Valley Chimney Sweep - Your Chimney Service Questions Answered" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="FAQ | Moreno Valley Chimney Sweep - Your Chimney Service Questions Answered" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* Blog Tags */}
                <meta name="PageType" content="article" />
                <meta name="ContentType" content="article" />
                <meta name="Category" content="article" />
                <meta name="SubCategories" content="article" />
                <meta name="FunnelStage" content="upper" />
                <meta name="Objective" content="education, seo" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.chimneysweepmorenovalley.org/faq" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="EFind answers to common questions about chimney care, inspections, cleanings, and repairs. Explore our FAQ page for expert advice and insights from Moreno Valley Chimney Sweep." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="EFind answers to common questions about chimney care, inspections, cleanings, and repairs. Explore our FAQ page for expert advice and insights from Moreno Valley Chimney Sweep." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="FAQ | Moreno Valley Chimney Sweep - Your Chimney Service Questions Answered" data-react-helmet="true" />
                <meta name="keywords" content="chimney sweep, chimney Inspection, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Repair, Fireplace Maintenance, Dryer Vent Cleaning, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Repair, Fireplace Maintenance, Dryer Vent Cleaning, chimney services, chimney service, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Repair, Fireplace Maintenance, Dryer Vent Cleaning, Chimney Insulation, Chimney Installation, Chimney Liner Repair, Chimney Pointing, Chimney Rain Cap Installation, Chimney Sweep, Chimney Crown Repair, Chimney Restoration, Chimney Flue Installation, Chimney Flue Repair, ​Chimney Construction, Chimney Vent Installation, Chimney Flashing, Flexible Chimney Liner Installation, Chimney Damper Repair, Chimney Cap Repair, Fireplace Insulation, Fireplace Installation, Fireplace Liner Repair, Fireplace Pointing, Fireplace Rain Cap Installation, Fireplace Repair, Fireplace Crown Repair, Fireplace Restoration, Fireplace Flue Installation, Fireplace Flue Repair, Ffireplace Construction, Fireplace Vent Installation, Fireplace Flashing, Flexible fireplace Liner Installation, Fireplace Damper Repair, Fireplace Cap, Faq Page" data-react-helmet="true" />
            </Helmet>
            <div className="FaqMainBanner">
                <h1>Chimney Sweep FAQs</h1>
                <h2>For expert Chimney Sweep services, reach out to Moreno Valley Chimney Sweep. Our certified technicians are committed to delivering reliable, high-quality repairs to ensure your chimney remains safe and fully functional.</h2>
                <h5>Categories</h5>
                <div className="FaqMainBannerCategories">
                    <a href="/faq#chimney-cleaning">Chimney Cleaning</a>
                    <a href="/faq#chimney-inspection">Chimney Inspection</a>
                    <a href="/faq#chimney-repair">Chimney Repair</a>
                    <a href="/faq#chimney-sweep">Chimney Sweep</a>
                    <a href="/faq#chimney-liner-installation">Chimney Liner Installation</a>
                    <a href="/faq#chimney-crown-repair">Chimney Crown Repair</a>
                    <a href="/faq#chimney-flashing-repair">Chimney Draft Issues</a>
                </div>
                <a className="BlogAsideCall" href="tel:951-456-4365">CALL 951-456-4365<img src={telephone} alt="Chimney Sweep In Moreno Valley, CA | Chimney Repair In Moreno Valley, CA | Chimney Services In Moreno Valley, CA | Chimney Inspection In Moreno Valley, CA" /></a>
                <a className="BlogAsideCall" href="/book">SCHEDULE ONLINE<img src={book} alt="Chimney Sweep In Moreno Valley, CA | Chimney Repair In Moreno Valley, CA | Chimney Services In Moreno Valley, CA | Chimney Inspection In Moreno Valley, CA" /></a>


            </div>
            {/* FaqMainList */}
            <div className="FaqMainList">
                {faqlistInputValue ?
                    <div className="FaqList">
                        <div className="FaqListHeaderResults">
                            <h6>Results</h6>
                            <h5 onClick={() => setfaqlistInputValue("")}>Restart Results</h5>
                        </div>
                        {faqlistFilter.filter(a =>
                            a.question.toLocaleLowerCase().includes(faqlistInputValue.toLocaleLowerCase()) ||
                            a.questionPath.toLocaleLowerCase().includes(faqlistInputValue.toLocaleLowerCase())
                        ).map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}

                        {faqlistFilter.length ?
                            <div className="FaqListNoResult">
                                <h1>We're sorry, but your search did not return any results.</h1>
                            </div>
                            : null}

                        <div className="FaqPageHeaderImagePromise">
                            <div className="FaqPageHeaderImagePromiseTop">
                                <div className="FaqPageHeaderImagePromiseTopLeft">
                                    <img src={Logo} alt="Chimney Sweep In Moreno Valley, CA | Chimney Repair In Moreno Valley, CA | Chimney Services In Moreno Valley, CA | Chimney Inspection In Moreno Valley, CA" />
                                    <h2>Moreno Valley Chimney Sweep promise</h2>
                                </div>
                                <a href="/apply">More info</a>
                            </div>
                            <p>For professional Chimney Sweep services, contact Moreno Valley Sweep. Our certified technicians are dedicated to providing reliable and high-quality repairs to keep your chimney safe and functional.</p>
                        </div>
                    </div>
                    :
                    <div className="FaqList">
                        <h6 id="chimney-cleaning">Chimney Cleaning</h6>
                        {faqlistFilter.filter(a => a.category == "Chimney Cleaning").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="chimney-inspection">Chimney Inspection</h6>
                        {FaqList.filter(a => a.category == "Chimney Inspection").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="chimney-repair">Chimney Repair</h6>
                        {FaqList.filter(a => a.category == "Chimney Repair").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="chimney-sweep">Chimney Sweep</h6>
                        {FaqList.filter(a => a.category == "Chimney Sweep").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="chimney-liner-installation">Chimney Liner Installation</h6>
                        {FaqList.filter(a => a.category == "Chimney Liner Installation").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="chimney-crown-repair">Chimney Crown Repair</h6>
                        {FaqList.filter(a => a.category == "Chimney Inspection").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="Chimney-Draft-Issues">Chimney Draft Issues</h6>
                        {FaqList.filter(a => a.category == "Chimney Draft Issues").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                    </div>
                }
                <aside className="FaqSearch">
                    <h1>Categories</h1>
                    <div className="FaqMainBannerCategories">
                        <a style={location.hash == "#chimney-cleaning" ? { "borderBottom": "2px solid #2a1768" } : null} href="/faq#chimney-cleaning">Chimney Cleaning</a>
                        <a style={location.hash == "#chimney-inspection" ? { "borderBottom": "2px solid #2a1768" } : null} href="/faq#chimney-inspection">Chimney Inspection</a>
                        <a style={location.hash == "#chimney-repair" ? { "borderBottom": "2px solid #2a1768" } : null} href="/faq#chimney-repair">Chimney Repair</a>
                        <a style={location.hash == "#chimney-sweep" ? { "borderBottom": "2px solid #2a1768" } : null} href="/faq#chimney-sweep">Chimney Sweep</a>
                        <a style={location.hash == "#chimney-liner-installation" ? { "borderBottom": "2px solid #2a1768" } : null} href="/faq#chimney-liner-installation">Chimney Liner Installation</a>
                        <a style={location.hash == "#chimney-crown-repair" ? { "borderBottom": "2px solid #2a1768" } : null} href="/faq#chimney-crown-repair">Chimney Crown Repair</a>
                        <a style={location.hash == "#Chimney-Draft-Issues" ? { "borderBottom": "2px solid #2a1768" } : null} href="/faq#chimney-flashing-repair">Chimney Draft Issues</a>
                    </div>
                    <h1>Search for question</h1>
                    <div className="FaqSearchInput">
                        <input
                            value={faqlistInputValue || ''}
                            onFocus={() => {
                                window.screen.width >= 850 ? window.scrollTo(0, 420) : window.scrollTo(0, 570)
                            }}
                            onChange={(e) => {
                                setfaqlistInputValue(e.target.value)
                                window.screen.width >= 850 ? window.scrollTo(0, 420) : window.scrollTo(0, 570)

                            }} type="text" placeholder="Search..." />
                        {faqlistInputValue ?
                            <img onClick={() => setfaqlistInputValue("")} src={closeMenu} alt="Chimney Sweep In Moreno Valley, CA | Chimney Repair In Moreno Valley, CA | Chimney Services In Moreno Valley, CA | Chimney Inspection In Moreno Valley, CA" />
                            :
                            null}
                    </div>
                </aside>
            </div>

            <div className="BlogsListReview">
                <div className="BlogsListReviewHead">
                    <h1>Related Articles</h1>
                </div>
                <div className="BlogsListReviewList">
                    {Blogs.slice(getNumberBlog, (getNumberBlog + 3)).map((A, index) =>
                        <a href={`/blog/${A.BlogPath.replace(/ /g, "_") + "_" + (A.BlogId - 1)}`} key={index} className="BlogsListReviewBox" >
                            <article>

                                <div className="BlogsListReviewBoxLeft">
                                    <img src={A.Photos} alt={A.BlogPath} />
                                </div>
                                <div className="BlogsListReviewBoxRight">
                                    <h1>{A.Title}</h1>
                                    <span>
                                        <h5>{readingTime(A)} MIN READ</h5>
                                        <h4>{A.Date}</h4>
                                    </span>
                                </div>
                            </article>

                        </a>
                    )}
                </div>
            </div>


            <NavigatorPath />

        </div >
    )
}

